import React from 'react';

interface PartnershipsSectionProps {
  partnerships: Array<{
    name: string;
    logo: {
      altText: string;
      localFile: {
        url: string;
      };
    };
  }>;
}

const PartnershipsSection = ({ partnerships }: PartnershipsSectionProps) => (
  <section>
    <div className="container mb-36">
      <h2
        className="px-4 mb-6 text-3xl text-center text-transparent uppercase sm:text-5xl md:text-6xl bold font-heading"
        style={{
          WebkitTextStrokeWidth: 1,
          WebkitTextStrokeColor: '#091F40',
        }}
      >
        Previous partnerships
      </h2>
      <div className="grid max-w-[200px] grid-cols-1 mx-auto md:max-w-5xl md:grid-cols-5 gap-18">
        {partnerships.map((partnership) => (
          <div
            key={partnership.name}
            className="flex flex-col items-center text-center"
          >
            <div className="flex items-center h-20">
              <img
                src={partnership.logo.localFile.url}
                alt={partnership.logo.altText}
                className="object-contain"
              />
            </div>
            {/* <div className="font-bold leading-6 tracking-wide text-navy-blue">
              {partnership.name}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default PartnershipsSection;
