import { useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import Button from '../atoms/Button';
import TheSaltyCursive from '../atoms/TheSaltyCursive';

interface CommunityFeatureSectionProps {
  id: string;
  heading: string;
  body: string;
  buttonLabel: string;
  buttonUrl: string;
}

const CommunityFeatureSection = ({
  id,
  heading,
  body,
  buttonLabel,
  buttonUrl,
}: CommunityFeatureSectionProps) => {
  const { scrollY } = useViewportScroll();
  const x1 = useTransform(scrollY, [0, 2600], [-150, 100]);

  return (
    <section className="relative pt-24 pb-24 lg:pb-36" id={id}>
      <div className="absolute left-0 right-0 h-full overflow-hidden -bottom-80 md:-bottom-16 -z-1">
        <TheSaltyCursive
          className="absolute bottom-0 right-0 hidden w-auto h-56 md:bottom-12 md:h-80 sm:block"
          style={{ x: x1 }}
        />
      </div>
      <div className="container text-center">
        <div className="max-w-3xl mx-auto">
          <h1 className="mb-4 u-h2">{heading}</h1>
          <p className="mb-6 u-p">{body}</p>
          <div className="flex justify-center">
            <Button to={buttonUrl} variant="default">
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommunityFeatureSection;
