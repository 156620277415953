export const useScrollTo = () => {
  const headerOffset = 92;

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) {
      return;
    }

    window.scrollTo({ top: el.offsetTop - headerOffset, behavior: 'smooth' });
  };

  return { scrollTo };
};
