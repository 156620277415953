import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import CommunityFeatureSection from '../components/organisms/CommunityFeatureSection';
import CommunityHero from '../components/organisms/CommunityHero';
import LocationGallery from '../components/organisms/LocationGallery';
import NewsletterSection from '../components/organisms/NewsletterSection';
import PartnershipsSection from '../components/organisms/PartnershipsSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Community = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <CommunityHero image={page.community.communityhero.image} />
        <CommunityFeatureSection
          {...page.community.communityfeature}
          id="community-feature"
        />
        <LocationGallery images={page.community.gallery} />
        <PartnershipsSection partnerships={page.community.partnerships} />
        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query CommunityPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      community {
        communityhero {
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1440, placeholder: BLURRED)
              }
            }
          }
        }
        communityfeature {
          heading
          body
          buttonLabel
          buttonUrl
        }
        gallery {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED)
            }
          }
        }
        partnerships {
          name
          logo {
            altText
            localFile {
              url
            }
          }
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Community;
